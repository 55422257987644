import React, { useState, useEffect, useRef } from 'react';
import EventDetails from './EventDetails'
import Header from './Header'
import MapView from './MapView';

function App() {

    var container = {};
    window.location.search.split('&').toString().substr(1).split(",").forEach(item => {
        container[item.split("=")[0]] = decodeURIComponent(item.split("=")[1]) ?  item.split("=")[1]: "No query strings available" ;
    });
    const eventId = container["eventId"]


    const renderContent = () => {
        if (!!eventId) {
            return renderEventDetails()
        } else {
            return renderMap()
        }
    }

    const renderMap = () => {
        return (
                <MapView/>
            )
    }

    const renderEventDetails = () => {
        return (
                <EventDetails/>
            )
    }

    return (
        <React.Fragment>
           <header id="header">
                <Header />
           </header>
           <div className="App" id="app">
              {renderContent()}
           </div>
        </React.Fragment>
    );
}

export default App;